import React, { useEffect, useState } from "react";
import {
  fadeInOut,
  staggerItems,
  item,
  dropIn,
} from "../../../../utils/animations";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";
import Confetti from "react-confetti";
import useWindowDimensions from "../../../../utils/useWindowDimensions";

import {
  showSuccessReducer,
  completedGameReducer,
  showFailReducer,
  currentStepReducer,
} from "../../../../store/gameSlice";

import Card from "./Card";
import CountDown from "../../../../components/CountDown/CountDown";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import HeadingStatus from "../../../../components/HeadingStatus/HeadingStatus";
import Button from "../../../../components/Button/Button";

import soundStart from "../../../../assets/sounds/start.wav";
import soundTick from "../../../../assets/sounds/tick.wav";
import soundTickWin from "../../../../assets/sounds/tick-win.wav";
import soundWin from "../../../../assets/sounds/win.mp3";
import soundLost from "../../../../assets/sounds/lost.mp3";

const dataZacaranSpomin = [
  [
    "memory1_a",
    "memory1_b",
    "memory2_a",
    "memory2_b",
    "memory3_a",
    "memory3_b",
    "memory4_a",
    "memory4_b",
    "memory5_a",
    "memory5_b",
    "memory6_a",
    "memory6_b",
  ],
  [
    "2memory1_a",
    "2memory1_a",
    "2memory2_a",
    "2memory2_a",
    "2memory3_a",
    "2memory3_a",
    "2memory4_a",
    "2memory4_a",
    "2memory5_a",
    "2memory5_a",
    "2memory6_a",
    "2memory6_a",
    "2memory7_a",
    "2memory7_a",
    "2memory8_a",
    "2memory8_a",
  ],
];

function Game({ gameData }) {
  const currentStep = useSelector((state) => state.game.currentStep);
  const showSuccess = useSelector((state) => state.game.showSuccess);
  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [data, setData] = useState([]);
  const [deck, setDeck] = useState(genDeck());

  const [errors, setErrors] = useState(0);
  const [matches, setMatch] = useState([]);
  const [flipping, setFlip] = useState({ i: [], v: [] });
  const [isLoading, setIsLoading] = useState(false);

  const [showBoost, setShowBoost] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const dispatch = useDispatch();

  const { height, width } = useWindowDimensions();

  const [playStart] = useSound(soundStart, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTick] = useSound(soundTick, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickWin] = useSound(soundTickWin, {
    volume: 0.8,
    soundEnabled: isPlaying,
  });

  const [playWin] = useSound(soundWin, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  useEffect(() => {
    if (flipping.i.length === 2) {
      checkFlip(flipping);
    }

    // Next level if finished

    if (finished) {
      setTimeout(() => {
        if (deck.length === dataZacaranSpomin[0].length) {
          dispatch(currentStepReducer(1));
          reshuffle();
          setShowConfetti(true);
        }

        if (deck.length === dataZacaranSpomin[1].length) {
          dispatch(completedGameReducer("zacaranspomin"));
          dispatch(showSuccessReducer(true));
          playWin();
          window.plausible("p_success_zacaran_spomin");
        }
      }, 500);
    }
  }, [flipping]);

  useEffect(() => {
    setIsLoading(true);

    var arr = dataZacaranSpomin[currentStep].map(function (i) {
      return i;
    });

    // there might be better ways of doing this
    let shuffled = arr.sort(() => Math.random() - 0.5);

    setData(shuffled);

    setIsLoading(false);

    // Set countdown
  }, [currentStep]);

  useEffect(() => {
    if (errors > 0) {
      setShowBoost(true);
      playTick();
    }

    setTimeout(() => {
      setShowBoost(false);
    }, 500);
  }, [errors]);

  useEffect(() => {
    window.plausible("p_start_zacaran_spomin");
  }, []);

  function genDeck() {
    let shuffled = dataZacaranSpomin[currentStep].sort(
      () => Math.random() - 0.5
    );

    return shuffled;
  }

  function checkFlip(f) {
    if (f.v[0] === f.v[1]) {
      setMatch([...matches, f.v[0]]);
      setFlip({ i: [], v: [] });
      playTickWin();
    } else {
      setErrors(() => errors + 1);

      setTimeout(() => {}, 150);

      setTimeout(() => {
        setFlip({ i: [], v: [] });
      }, 500);
    }
  }

  function reshuffle() {
    setFlip({ i: [], v: [] });
    setMatch([]);
    setErrors(0);

    setTimeout(() => setDeck(data), 500);
  }

  function cardClick(value, index) {
    const { i, v } = flipping;
    if (i.length < 2) {
      setFlip({ i: [...i, index], v: [...v, value] });
    }
  }
  const cards = data.map((value, i) => (
    <Card
      key={i}
      onClick={() => cardClick(value.substring(0, value.length - 2), i)}
      value={"https://escapebox.si/temp/lidlharry/spomin/" + value}
      matched={matches.includes(value.substring(0, value.length - 2))}
      flipping={flipping.i.includes(i)}
    />
  ));

  const finished = matches.length === deck.length / 2;

  const success = () => {
    dispatch(completedGameReducer("zacaranspomin"));
    dispatch(showSuccessReducer(true));
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`messages ingame d-flex justify-content-center align-items-center ${gameData.id}`}>
      <HeadingStatus />
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          colors={["#bfa266", "#642076"]}
          numberOfPieces={200}
          friction={0.99}
          gravity={0.3}
          tweenDuration={500}
          recycle={false}
          opacity={0.8}
          drawShape={(ctx) => {
            function strokeStar(x, y, r, n, inset) {
              ctx.save();
              ctx.beginPath();
              ctx.translate(x, y);
              ctx.moveTo(0, 0 - r);
              for (var i = 0; i < n; i++) {
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r * inset);
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r);
              }
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            }
            strokeStar(5, 6, 3, 4, 4);
          }}
        />
      )}

      <div className="w-100 text-center">
        <Wrapper className="wrapper__narrow">
          <CountDown
            currentStep={currentStep}
            errors={errors}
            gameData={gameData}
          />

          <div className="mt-3 px-sm-6">
            {showBoost ? (
              <div className="score-booster">
                <span>-1s</span>
              </div>
            ) : null}
            <motion.div
              variants={dropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
              key={currentStep}
              className="deck">
              {cards}
            </motion.div>
          </div>
          <div className="dev-buttons">
            <Button
              label="Zaključi kot success"
              onClick={success}
              className="m-1 btn btn-sm btn-texture mt-4"
            />

            <Button
              label="Zaključi kot fail"
              onClick={() => dispatch(showFailReducer(true))}
              className="m-1 btn btn-sm btn-texture mt-4"
            />
          </div>
        </Wrapper>
      </div>
    </motion.div>
  );
}

export default Game;
