import axios from "axios";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { fadeInOut } from "../../utils/animations";
import { showOptInReducer, optInReducer } from "../../store/gameSlice";
import {
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import Wrapper from "../../components/Wrapper/Wrapper";
import HeadingStatus from "../../components/HeadingStatus/HeadingStatus";
import Button from "../../components/Button/Button";

import { imgKlubuk } from "../../assets";

function OptIn({ step, setStep }) {
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const updateUser = (e) => {
    let data = {
      quizId: "81ed7d7c-1d3a-lhp1",
      name: name,
      email: email,
      age_group: ageGroup,
      newsletter: newsletter,
      terms: terms,
    };

    setLoading(true);
    setIsError(false);
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setLoading(false);
    } else if (localStorage.getItem("token") === null) {
      axios
        .post("https://api.escapebox.si/quizbox/user", data)
        .then((res) => {
          setData(res.data);
          localStorage.setItem("token", JSON.stringify(res.data.token));
          dispatch(showOptInReducer(false));
          dispatch(optInReducer(true));
          setIsSuccessfullySubmitted(true);
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            setValidated(false);
            if (error.response.data.error === "email_already_exists") {
              setErrorMessage("Uporabnik s tem email-om je že sodeloval.");
            }
            setIsError(true);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          setLoading(false);
        });
    } else {
      axios
        .put("https://api.escapebox.si/quizbox/user", data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setData(res.data);
          dispatch(showOptInReducer(false));
          dispatch(optInReducer(true));
          setIsSuccessfullySubmitted(true);
          setLoading(false);
        })

        .catch(function (error) {
          if (error.response) {
            setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            if (error.response.data.error === "email_already_exists") {
              setErrorMessage("Uporabnik s tem email-om je že sodeloval.");
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          setLoading(false);
        });
    }

    setValidated(true);
  };

  const clickHandler = (optin) => {
    if (optin === "true") {
    } else if (optin === "false" && localStorage.getItem("token") === null) {
      dispatch(optInReducer(false));
      let data = {
        quizId: "81ed7d7c-1d3a-lhp1",
        guest: true,
      };
      axios
        .post("https://api.escapebox.si/quizbox/user", data)
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("token", JSON.stringify(res.data.token));

          dispatch(showOptInReducer(false));
          console.log(data, res.data);
        })
        .then((res) => {
          console.log(res.data);

          //setData(res.data);
          //setIsOpen(false);
          //setLocalStorage();
          //setErrorMessage("");
        })

        .catch(function (error) {
          if (error.response) {
            //setValidated(false);
            //setErrorMessage(error.response.data.error_desc);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          //setLoading(false);
        });
    } else if (optin === "guest") {
      //setStep("final");
      dispatch(optInReducer("guest"));
      dispatch(showOptInReducer(false));
      //dispatch(optInReducer(true));
    } else {
      dispatch(showOptInReducer(false));
    }
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="messages d-flex justify-content-center align-items-center">
      <HeadingStatus />

      <div className="w-100 text-center">
        <Wrapper className="optin wrapper__narrow text-center">
          {<img className="klobuk mb-2" src={imgKlubuk} />}

          {step === "before" ? (
            <h2 className="mt-3 pt-4 ">
              Poteguj se za najbolj čarobne nagrade!{" "}
            </h2>
          ) : (
            <h2 className="mt-3 pt-4">
              Zadnja priložnost, da osvojiš lepe nagrade!{" "}
            </h2>
          )}

          <p>
            Prišepni mi svoje podatke in se pridruži čarovnikom in čarovnicam,
            ki sodelujejo v nagradnem žrebu.{" "}
          </p>

          <Form noValidate validated={validated} onSubmit={updateUser}>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="Name">
                <FloatingLabel controlId="Name" label="Ime in priimek">
                  <Form.Control
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Ime in priimek"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Prosimo, vpišite ime in priimek.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col} controlId="Email">
                <FloatingLabel controlId="Email" label="Email" className="mb-3">
                  <Form.Control
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    isInvalid={errorMessage && "true"}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorMessage
                      ? errorMessage
                      : "Prosimo, vpišite email naslov."}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <div className="row g-2 text-start">
              <div className="col-12">
                <p className="lead mb-1">Koliko let imaš?</p>
                <Row>
                  <Form.Group className="mb-1">
                    <Form.Check
                      inline
                      label="do 10 let"
                      name="group1"
                      type="radio"
                      id="ena"
                      value="10"
                      onChange={(e) => setAgeGroup(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="11 - 17 let"
                      name="group1"
                      type="radio"
                      id="dva"
                      value="11-17"
                      onChange={(e) => setAgeGroup(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="18+ let"
                      name="group1"
                      type="radio"
                      id="tri"
                      value="18+"
                      onChange={(e) => setAgeGroup(e.target.value)}
                    />
                  </Form.Group>
                </Row>

                <Row className="mt-4">
                  <Form.Group className="mb-1">
                    <Form.Check id="optin" type="checkbox">
                      <Form.Check.Input
                        onChange={(e) => setTerms(e.target.checked)}
                        type="checkbox"
                        required
                      />
                      <Form.Check.Label>
                        Strinjam se s{" "}
                        <a
                          className="link-secondary fw-bold text-lidlblue"
                          target="_blank"
                          href="https://www.lidl.si/file-download/111234/1764011/Pravila%20in%20pogoji%20nagradne%20igre%20%C4%8Carobni%20preizkusi.pdf">
                          {" "}
                          Pravili in pogoji
                        </a>{" "}
                        nagradne igre Čarobni preizkusi.
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Strinjati se morate s pravili in pogoji.
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-1">
                    <Form.Check id="newsletter" type="checkbox">
                      <Form.Check.Input
                        onChange={(e) => setNewsletter(e.target.checked)}
                        type="checkbox"
                      />
                      <Form.Check.Label>
                        Želim se prijaviti na Lidlove spletne novice.
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Row>
              </div>

              <div className="col-12">
                {isError && (
                  <div style={{ bottom: "0" }} className="position-absolute">
                    <p className="text-center lead text-red fw-bold mb-0">
                      Ta račun ne obstaja.
                    </p>
                  </div>
                )}

                {step === "before" ? (
                  <>
                    <Button
                      label="Igraj"
                      className="btn btn-lidlblue w-100 mb-2"
                    />

                    <a
                      href="#"
                      onClick={() => clickHandler("false")}
                      className="btn btn-outline-lidlblue fs-6 py-2 w-100">
                      Igraj kot gost
                    </a>

                    <p className="small text-center pt-1">
                      Toda brez možnosti za osvojitev{" "}
                      <Link to="/nagrade">
                        <strong>nagrad</strong>
                      </Link>
                      .
                    </p>
                  </>
                ) : (
                  <>
                    <Button
                      label="Sodeluj v žrebu"
                      className="btn btn-lidlblue w-100 mb-2"
                    />

                    <a
                      href="#"
                      onClick={() => clickHandler("guest")}
                      className="btn btn-sm w-100 mt-1">
                      Končaj brez sodelovanja
                    </a>
                  </>
                )}
              </div>
            </div>
          </Form>
        </Wrapper>
      </div>
    </motion.div>
  );
}

export default OptIn;
