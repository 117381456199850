import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFailReducer } from "../../store/gameSlice";
import { ReactComponent as ImgClock } from "../../assets/images/icon_clock.svg";
import useSound from "use-sound";
import soundLost from "../../assets/sounds/lost.mp3";

function CountDown({ currentStep, errors, gameData }) {
  const dispatch = useDispatch();
  const isPlaying = useSelector((state) => state.game.isPlaying);
  const [timerClock, setTimerClock] = useState(gameData.timer[0]);

  const [playLost] = useSound(soundLost, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  useEffect(() => {
    const timer = setTimeout(function () {
      setTimerClock(timerClock - 1);
    }, 10);

    if (timerClock <= 0) {
      dispatch(showFailReducer(true));
      playLost();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [timerClock]);

  useEffect(() => {
    if (errors > 0) {
      setTimeout(() => {
        setTimerClock(timerClock - 100);
      }, 400);
    }
  }, [errors]);

  useEffect(() => {
    if (currentStep > 0) {
      setTimerClock(gameData.timer[currentStep]);
    }
  }, [currentStep]);

  return (
    <>
      <div
        className={`game-status ${gameData.id} px-2 row g-0 d-flex align-items-center text-start`}>
        <div className="col-3 col-sm-2 lh-1">{gameData.headline}</div>

        <div className="col-5 col-sm-7 pe-2">
          <div className="round-time-bar">
            <div
              style={{
                width: (timerClock * 100) / gameData.timer[currentStep] + "%",
              }}
              className={timerClock < 500 ? "red" : null}></div>
          </div>

          <div className="d-none">
            <span onClick={() => setTimerClock(timerClock - 10)}>MINUS</span>
          </div>
        </div>

        <div className="col-2 col-sm-1 text-center d-flex justify-content-center">
          <ImgClock />
          <span className="ms-1">{(timerClock / 100).toFixed(0)} s</span>
        </div>

        <div className="col-2 text-end">{currentStep + 1}. NIVO</div>
      </div>
    </>
  );
}

export default CountDown;
