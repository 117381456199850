import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Import views and components

import CarobniMagneti from "./Games/CarobniMagneti/CarobniMagneti";
import SkrivnostneRazlike from "./Games/SkrivnostneRazlike/SkrivnostneRazlike";
import ZacaranSpomin from "./Games/ZacaranSpomin/ZacaranSpomin";

import Intro from "./Intro/Intro";
import OptIn from "../OptIn/OptIn";
import Success from "./Success/Success";
import Fail from "./Fail/Fail";

import { AnimatePresence } from "framer-motion";

// Import media

// Data
const data = {
  skrivnostnerazlike: {
    id: "skrivnostnerazlike",
    headline: "Skrivnostne razlike",
    description:
      "<h4 class='pb-2 text-blue'>Evanesco! </h4> <p>Pred teboj sta dve malenkost drugačni sliki s čarobnimi figuricami in nepozabnimi predmeti iz namizne igre Čarobne figurice. Poišči vse razlike in se prebij skozi dva nivoja, da uspešno rešiš to igro. 🤓</p>",
    success1:
      "Tvoje sokolje oko je odkrilo vse razlike med slikami! S svojo zbranostjo bi lahko čarovnikom in čarovnicam pomagal tudi v najbolj zahtevnih situacijah.",
    dataGame: [
      {
        id: "item1",
        name: "Predmet 1",
      },
      {
        id: "item2",
        name: "Predmet 2",
      },
      {
        id: "item3",
        name: "Predmet 3",
      },
      {
        id: "item4",
        name: "Predmet 4",
      },
      {
        id: "item5",
        name: "Predmet 5",
      },
    ],
    itemsNumber: [7, 10],
    timer: [2500, 3000],
  },
  zacaranspomin: {
    id: "zacaranspomin",
    headline: "Začaran spomin",
    description:
      "<h4 class='pb-2 text-blue'>Revelio!</h4> <p>Upamo, da te nista kakšna navihana čarovnica ali čarovnik uročila z urokom pozabe. Čaka te namreč igra spomina s čarobnimi figuricami in nepozabnimi elementi iz namizne igre Čarobne figurice!<br>Pobrskaj med sličicami dveh nivojev in poišči ustrezne pare. 🦉 </p>",
    success1:
      "Imaš odličen spomin! Tvoja pametna glav'ca je kos še tako zahtevnim čarobnim izzivom. Le tako naprej!",
    gameData: [
      ["memory1_a", "memory1_b", "memory2_a", "memory2_b"],
      ["memory3_a", "memory3_b", "memory4_a", "memory4_b"],
    ],
    timer: [3000, 4500],
  },
  carobnimagneti: {
    id: "carobnimagneti",
    headline: "Čarobni magneti",
    description:
      "<h4 class='pb-2 text-blue'>Accio!</h4> <p>Čas za iskanje parov! Vstopi v dve sobi in čarobne elemente iz namizne igre Čarobne figurice povleci k ustreznim čarobnim figuricam. Pokaži svojo ljubezen do čarobnega sveta Harryja Potterja. ⚡</p>",
    success1:
      "Čarobni svet imaš v mezincu. Poznaš vse junake, njihove domove in čarobne predmete.",
    dataGame: [
      {
        id: 0,
        dragImgs: [
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_prvi_zgoraj.png",
            ident: "ena",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_drugi_zgoraj.png",
            ident: "dva",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_tretji_zgoraj.png",
            ident: "tri",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_cetrti_zgoraj.png",
            ident: "stiri",
          },
        ],
        dropImgs: [
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_prvi_spodaj.png",
            ident: "tri",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_drugi_spodaj.png",
            ident: "dva",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_tretji_spodaj.png",
            ident: "ena",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step1_cetrti_spodaj.png",
            ident: "stiri",
          },
        ],
      },
      {
        id: 1,
        dragImgs: [
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_prvi_zgoraj.png",
            ident: "ena",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_drugi_zgoraj.png",
            ident: "dva",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_tretji_zgoraj.png",
            ident: "tri",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_cetrti_zgoraj.png",
            ident: "stiri",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_peti_zgoraj.png",
            ident: "pet",
          },
        ],
        dropImgs: [
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_prvi_spodaj.png",
            ident: "tri",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_drugi_spodaj.png",
            ident: "pet",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_tretji_spodaj.png",
            ident: "ena",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_cetrti_spodaj.png",
            ident: "dva",
          },
          {
            img: "https://harrypotter.escapebox.si/public/images/carobnimagneti/step2_peti_spodaj.png",
            ident: "stiri",
          },
        ],
      },
    ],
    timer: [1500, 1500],
  },
};

function Game() {
  const showIntro = useSelector((state) => state.game.showIntro);
  const showSuccess = useSelector((state) => state.game.showSuccess);
  const showFail = useSelector((state) => state.game.showFail);
  const showOptIn = useSelector((state) => state.game.showOptIn);
  const optIn = useSelector((state) => state.game.optIn);

  const { game } = useParams();

  const gameId = game.replace(/-/g, "");

  /* 
  const GameScreen = () => {
    if (
      showOptIn === true &&
      optIn === false
    ) {
      return <OptIn step="before" gameData={data[gameId]} />;
    }

    if (showIntro === true) {
      return <Intro path={game} id={gameId} gameData={data[gameId]} />;
    }

    if (showSuccess === true) {
      return <Success gameData={data[gameId]} />;
    }

    if (showFail === true) {
      return <Fail gameData={data[gameId]} />;
    }

    switch (game) {
      case "carobni-magneti":
        return <CarobniMagneti path={game} id={game} gameData={data[gameId]} />;
      case "skrivnostne-razlike":
        return (
          <SkrivnostneRazlike path={game} id={game} gameData={data[gameId]} />
        );
      case "zacaran-spomin":
        return <ZacaranSpomin path={game} id={game} gameData={data[gameId]} />;
      default:
        return null;
    }
  }; */

  return (
    <AnimatePresence>
      {/* <StatusBar /> */}
      {/* <h1>{renderCount.current++} </h1> */}
      {/* <GameScreen /> */}

      {showOptIn === true &&
      optIn === false &&
      showSuccess === false &&
      game === "skrivnostne-razlike" ? (
        <OptIn step="before" gameData={data[gameId]} />
      ) : showIntro === true ? (
        <Intro path={game} id={gameId} gameData={data[gameId]} />
      ) : showSuccess === true ? (
        <Success gameData={data[gameId]} />
      ) : showFail === true ? (
        <Fail gameData={data[gameId]} />
      ) : game === "skrivnostne-razlike" ? (
        <SkrivnostneRazlike path={game} id={game} gameData={data[gameId]} />
      ) : game === "zacaran-spomin" ? (
        <ZacaranSpomin path={game} id={game} gameData={data[gameId]} />
      ) : game === "carobni-magneti" ? (
        <CarobniMagneti path={game} id={game} gameData={data[gameId]} />
      ) : null}
    </AnimatePresence>
  );
}

export default Game;
