import { useState } from "react";
import { useSelector } from "react-redux";
import useSound from "use-sound";
import { DragDropContainer } from "react-drag-drop-container";
import { motion } from "framer-motion";
import { dropIn } from "../../../../utils/animations";
import soundStart from "../../../../assets/sounds/start.wav";
import soundTick from "../../../../assets/sounds/tick.wav";
import soundTickWin from "../../../../assets/sounds/tick-win.wav";
import soundWin from "../../../../assets/sounds/win.mp3";
import soundLost from "../../../../assets/sounds/lost.mp3";
function Drags({ targetKey, image, errors, setErrors }) {
  const [targetId, setTargetId] = useState();
  const [highLight, setHighlight] = useState(false);

  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [playTick] = useSound(soundTick, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickWin] = useSound(soundTickWin, {
    volume: 0.8,
    soundEnabled: isPlaying,
  });

  const [playWin] = useSound(soundWin, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const landedOn = (e) => {
    setTargetId(e.dragData);
  };

  const onDragEnd = (dragData, currentTarget, x, y) => {
    if (targetId !== dragData) {
      setErrors(() => errors + 1);
      setHighlight(true);
      playTick();
    }
  };

  const dragging = (dragData, currentTarget, x, y) => {
    //console.log("onDrag", dragData, currentTarget);
  };

  return (
    <motion.div
      style={{ display: "inline-block" }}
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit">
      <DragDropContainer
        targetKey={targetKey}
        onDrag={dragging}
        onDrop={landedOn}
        dragClone={false}
        onDragEnd={onDragEnd}
        dragData={highLight}>
        <img src={image} />
      </DragDropContainer>
    </motion.div>
  );
}

export default Drags;
