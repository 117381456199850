import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../utils/animations";
import {
  showIntroReducer,
  showSuccessReducer,
  showOptInReducer,
  currentStepReducer,
  completedGameReducer,
} from "../../store/gameSlice";

import Wrapper from "../../components/Wrapper/Wrapper";
import { imgBgGames } from "../../assets";
import GamesShieldIcon from "./GamesShieldIcon";
import HeadingStatus from "../../components/HeadingStatus/HeadingStatus";

function Map() {
  let navigate = useNavigate();
  const [restartGame, setRestartGame] = useState(false);

  const [next, setNext] = useState(false);

  const dispatch = useDispatch();
  const completedGame = useSelector((state) => state.game.completedGame);

  useEffect(() => {
    dispatch(showSuccessReducer(false));
    dispatch(showIntroReducer(true));
    dispatch(showOptInReducer(true));
    dispatch(currentStepReducer(0));

    if (
      completedGame[0].completed &&
      completedGame[1].completed &&
      completedGame[2].completed
    ) {
      setRestartGame(true);
    }
  }, []);

  function gameStatus0() {
    if (completedGame[0].completed) {
      return "done";
    } else {
      return "active";
    }
  }

  function gameStatus1() {
    if (completedGame[1].completed) {
      return "done";
    } else if (completedGame[0].completed) {
      return "active";
    } else {
      return "inactive";
    }
  }

  function gameStatus2() {
    if (completedGame[2].completed) {
      return "done";
    } else if (completedGame[0].completed && completedGame[1].completed) {
      return "active";
    } else {
      return "inactive";
    }
  }

  const clickHandlerRestart = () => {
    setRestartGame(false);
    localStorage.clear();

    window.location.href = "/";
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="messages games d-flex justify-content-center align-items-center">
      <HeadingStatus />
      <motion.div
        className="w-100 text-center"
        variants={staggerItems}
        initial="hidden"
        animate="show">
        {restartGame ? (
          <button
            onClick={clickHandlerRestart}
            className="btn btn-texture p-4 px-6">
            Igraj še enkrat
          </button>
        ) : (
          <>
            <motion.div
              className={`nav-shield first ${gameStatus0() + "_link"}`}
              variants={item}>
              <Link to="/game/skrivnostne-razlike">
                <GamesShieldIcon type={gameStatus0()} />
                <div className="textbox">
                  <h3>
                    Skrivnostne
                    <br />
                    razlike
                  </h3>
                </div>
              </Link>
            </motion.div>

            <motion.div
              className={`nav-shield second ${gameStatus1() + "_link"}`}
              variants={item}>
              <Link to="/game/zacaran-spomin">
                <GamesShieldIcon type={gameStatus1()} />
                <div className="textbox">
                  <h3>
                    Začaran
                    <br />
                    spomin
                  </h3>
                </div>
              </Link>
            </motion.div>

            <motion.div
              className={`nav-shield third ${gameStatus2() + "_link"}`}
              variants={item}>
              <Link to="/game/carobni-magneti">
                <GamesShieldIcon type={gameStatus2()} />
                <div className="textbox">
                  <h3>
                    Čarobni
                    <br />
                    magneti
                  </h3>
                </div>
              </Link>
            </motion.div>
          </>
        )}

        <div className="d-none">
          <Link to="/game/skrivnostne-razlike">
            <button
              className={`m-1 btn ${
                completedGame[0].completed ? "btn-texture" : "btn-outline-blue"
              }`}>
              Skrivnostne razlike
            </button>
          </Link>
          <Link to="/game/zacaran-spomin">
            <button
              className={`m-1 btn ${
                completedGame[1].completed ? "btn-texture" : "btn-outline-blue"
              }`}>
              Začaran spomin
            </button>
          </Link>
          <Link to="/game/carobni-magneti">
            <button
              className={`m-1 btn ${
                completedGame[2].completed ? "btn-texture" : "btn-outline-blue"
              }`}>
              Čarobni magneti
            </button>
          </Link>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Map;
