import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { fadeInOut, dropIn, scaleIn } from "../../../utils/animations";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Wrapper from "../../../components/Wrapper/Wrapper";
import HeadingStatus from "../../../components/HeadingStatus/HeadingStatus";
import OptIn from "../../OptIn/OptIn";
import Button from "../../../components/Button/Button";

import GamesShieldIcon from "../GamesShieldIcon";

import imgElementZacaranSpomin from "../../../assets/images/bg_element_zacaran_spomin.png";
import imgElementCarobniMagneti from "../../../assets/images/bg_element_carobnimagenti.png";
import imgElementSkrivnostneRazlike from "../../../assets/images/bg_element_skrivnostnerazlike.png";
import imgElementSuccess from "../../../assets/images/bg_success.jpg";
import imgKlobuk from "../../../assets/images/icon_klobuk.png";

import ShareFb from "../../../assets/images/share-fb.svg";

function Success({ gameData }) {
  const [step, setStep] = useState("init");
  const [allCompleted, setAllCompleted] = useState(false);

  const completedGame = useSelector((state) => state.game.completedGame);
  const optIn = useSelector((state) => state.game.optIn);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    // Check if all games are completed
    const allGamesCompleted = completedGame.every(
      (obj) => obj.completed === true
    );
    if (allGamesCompleted) {
      setAllCompleted(true);
    }

    //console.log("allCompleted",completedGame);
  }, []);

  useEffect(() => {
    if (step === "final") {
      axios
        .post(
          "https://api.escapebox.si/quizbox/basic/81ed7d7c-1d3a-lhp1/complete",
          {},
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          window.plausible("p_success_all");
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  }, [step]);

  const SuccessScreen = () => {
    return (
      <>
        {step === "init" ? (
          <>
            <Confetti
              width={width}
              height={height}
              colors={["#bfa266", "#642076"]}
              numberOfPieces={100}
              opacity={0.8}
              drawShape={(ctx) => {
                function strokeStar(x, y, r, n, inset) {
                  ctx.save();
                  ctx.beginPath();
                  ctx.translate(x, y);
                  ctx.moveTo(0, 0 - r);
                  for (var i = 0; i < n; i++) {
                    ctx.rotate(Math.PI / n);
                    ctx.lineTo(0, 0 - r * inset);
                    ctx.rotate(Math.PI / n);
                    ctx.lineTo(0, 0 - r);
                  }
                  ctx.closePath();
                  ctx.fill();
                  ctx.restore();
                }
                strokeStar(5, 6, 3, 4, 4);

                /*         ctx.beginPath()
        for(let i = 0; i < 10; i++) {
          const angle = 0.35 * i
          const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
          const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
          ctx.lineTo(x, y)
        }
        ctx.stroke()
        ctx.closePath() */
              }}
            />
            <motion.div
              variants={fadeInOut}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="messages success d-flex justify-content-center align-items-center">
              <HeadingStatus />
              <img
                className={`bg-element ${gameData.id}`}
                src={
                  gameData.id === "zacaranspomin"
                    ? imgElementZacaranSpomin
                    : gameData.id === "carobnimagneti"
                    ? imgElementCarobniMagneti
                    : imgElementSkrivnostneRazlike
                }
              />
              <div className="w-100 text-center">
                <Wrapper className="wrapper__full-width">
                  <div className="content ">
                    <motion.div
                      className="success"
                      variants={scaleIn}
                      initial="hidden"
                      animate="visible"
                      exit="exit">
                      <GamesShieldIcon type="success" />
                    </motion.div>
                    <h2 className="mt-4">Uspelo ti je! Preizkus rešen.</h2>
                    <p className="lead">{gameData.success1}</p>
                  </div>
                </Wrapper>

                <div className="button-container">
                  <div className="row">
                    <div className="col-12">
                      {allCompleted ? (
                        <Button
                          label="Končaj igro"
                          onClick={() => setStep("final")}
                          className="btn btn-texture mt-4"
                        />
                      ) : (
                        <Link to="/games">
                          <Button
                            label="Igraj naslednjo igro"
                            onClick={null}
                            className="btn btn-texture mt-4"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        ) : step === "final" ? (
          <>
            {optIn === false ? (
              <OptIn setStep={setStep} step="after" />
            ) : (
              <motion.div
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="messages success-final d-flex justify-content-center align-items-center">
                <HeadingStatus />
                <div className="w-100 text-center">
                  <Wrapper className="wrapper__full-width">
                    <div className="content ">
                      <img className="success w-25" src={imgKlobuk} />
                      <h1 className="mt-4 display-2">Vragolija opravljena! </h1>

                      {optIn === "guest" ? (
                        <>
                          <p className="lead">
                            Vse preizkuse si uspešno prestal/a, a dogodivščina
                            še ni zaključena!
                          </p>
                          <p>
                            Obišči Lidl in se pozabavaj z Wizarding World
                            zbirateljskim albumom z namizno igro, zbiraj nalepke
                            in pridobi svojo čarobno skodelico po akcijski ceni.
                            Kako? Preveri na povezavi!
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="lead">
                            Uvrstil/a si se na seznam čarovnikov in čarovnic, ki
                            sodelujejo v žrebu za čarobne nagrade.
                          </p>
                          <p>
                            Da lažje pričakaš žreb, se pozabavaj še z Wizarding
                            World namizno igro z zbirateljskim albumom iz Lidla,
                            zbiraj nalepke in pridobi svojo čarobno skodelico po
                            akcijski ceni. Kako? Preveri na povezavi!
                          </p>
                        </>
                      )}
                    </div>
                  </Wrapper>

                  <div className="button-container">
                    <div className="row">
                      <div className="col-12">
                        <a
                          target="_blank"
                          href="https://www.lidl.si/carobni-svet "
                          className="btn btn-texture mt-0 mt-sm-4">
                          Preberi več
                        </a>
                      </div>
                    </div>
                    <div className="row mt-1 g-2">
                      <div className="col-12">
                        <Link to="/">
                          <Button
                            label="Igraj ponovno"
                            className="btn btn-sm btn-outline-white"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="row mt-1 g-2">
                      <div className="col-4">
                        <Link to="/nagrade">
                          <Button
                            label="Nagrade"
                            className="btn btn-sm btn-outline-white"
                          />
                        </Link>
                      </div>
                      <div className="col-8">
                        <Link to="/games">
                          <Button
                            label="Pravila in pogoji"
                            className="btn btn-sm btn-outline-white"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      {step === "init" ? (
        <>
          <Confetti
            width={width}
            height={height}
            colors={["#bfa266", "#642076"]}
            numberOfPieces={200}
            opacity={0.8}
            drawShape={(ctx) => {
              function strokeStar(x, y, r, n, inset) {
                ctx.save();
                ctx.beginPath();
                ctx.translate(x, y);
                ctx.moveTo(0, 0 - r);
                for (var i = 0; i < n; i++) {
                  ctx.rotate(Math.PI / n);
                  ctx.lineTo(0, 0 - r * inset);
                  ctx.rotate(Math.PI / n);
                  ctx.lineTo(0, 0 - r);
                }
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              }
              strokeStar(5, 6, 3, 4, 4);
            }}
          />
          <motion.div
            variants={fadeInOut}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={`messages d-flex justify-content-center align-items-center ${gameData.id}`}>
            <HeadingStatus />
            <img
              className={`bg-element ${gameData.id}`}
              src={
                gameData.id === "zacaranspomin"
                  ? imgElementZacaranSpomin
                  : gameData.id === "carobnimagneti"
                  ? imgElementCarobniMagneti
                  : imgElementSkrivnostneRazlike
              }
            />
            <div className="w-100 text-center">
              <Wrapper className="wrapper__full-width">
                <div className="content ">
                  <motion.div
                    className="success"
                    variants={scaleIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    <GamesShieldIcon type="success" />
                  </motion.div>
                  <h2 className="mt-4">Uspelo ti je! Preizkus rešen.</h2>
                  <p className="lead">{gameData.success1}</p>
                </div>
              </Wrapper>

              <div className="button-container">
                <div className="row">
                  <div className="col-12">
                    {allCompleted ? (
                      <Button
                        label="Končaj igro"
                        onClick={() => setStep("final")}
                        className="btn btn-texture mt-4"
                      />
                    ) : (
                      <Link to="/games">
                        <Button
                          label="Igraj naslednjo igro"
                          onClick={null}
                          className="btn btn-texture mt-4"
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      ) : step === "final" ? (
        <>
          {optIn === false ? (
            <OptIn setStep={setStep} step="after" />
          ) : (
            <motion.div
              variants={dropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="messages success-final d-flex justify-content-center align-items-center">
              <HeadingStatus />
              <div className="w-100 text-center">
                <Wrapper className="wrapper__full-width">
                  <div className="content ">
                    <img className="success" src={imgKlobuk} />
                    <h1 className="mt-2 display-5 fw-bold">
                      Vragolija opravljena!{" "}
                    </h1>

                    {optIn === "guest" ? (
                      <>
                        <p className="lead">
                          Vse preizkuse si uspešno prestal/a, a dogodivščina še
                          ni zaključena!
                        </p>
                        <p>
                          Obišči Lidl in se pozabavaj z Wizarding World
                          zbirateljskim albumom z namizno igro, zbiraj nalepke
                          in pridobi svojo čarobno skodelico po akcijski ceni.
                          Kako? Preveri na povezavi!
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="lead">
                          Uvrstil/a si se na seznam čarovnikov in čarovnic, ki
                          sodelujejo v žrebu za čarobne nagrade.
                        </p>
                        <p>
                          Da lažje pričakaš žreb, se pozabavaj še z Wizarding
                          World namizno igro z zbirateljskim albumom iz Lidla,
                          zbiraj nalepke in pridobi svojo čarobno skodelico po
                          akcijski ceni. Kako? Preveri na povezavi!
                        </p>
                      </>
                    )}
                  </div>
                </Wrapper>

                <div className="button-container">
                  <div className="row">
                    <div className="col-12">
                      <a
                        target="_blank"
                        href="https://www.lidl.si/carobni-svet "
                        className="btn btn-texture mt-0 mt-sm-4">
                        Preberi več
                      </a>
                    </div>
                  </div>
                  <div className="row mt-0 g-2">
                    <div className="col-12">
                      <Link to="/">
                        <Button
                          label="Igraj ponovno"
                          className="btn text-lidlblue btn-sm btn-white"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-0 g-2">
                    <div className="col-4">
                      <Link to="/nagrade">
                        <Button
                          label="Nagrade"
                          className="btn btn-sm btn-outline-white"
                        />
                      </Link>
                    </div>
                    <div className="col-8">
                      <a
                        className="btn btn-sm btn-outline-white"
                        href="https://www.lidl.si/file-download/111234/1764011/Pravila%20in%20pogoji%20nagradne%20igre%20%C4%8Carobni%20preizkusi.pdf"
                        target="_blank">
                        Pravila in pogoji
                      </a>
                    </div>
                  </div>

                  <FacebookShareButton url="https://harrypotter.escapebox.si">
                    <img className=" btn btn-white p-2 mt-2" src={ShareFb} />
                  </FacebookShareButton>
                </div>
              </div>
            </motion.div>
          )}
        </>
      ) : null}
    </>
  );
}

export default Success;
