import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { fadeInOut, staggerItems, item } from "../../../../utils/animations";
import HeadingStatus from "../../../../components/HeadingStatus/HeadingStatus";
import CountDown from "../../../../components/CountDown/CountDown";

import {
  showIntroReducer,
  showSuccessReducer,
  showFailReducer,
  completedGameReducer,
} from "../../../../store/gameSlice";

import Wrapper from "../../../../components/Wrapper/Wrapper";
import Button from "../../../../components/Button/Button";
import Razlike from "./Razlike";

function SkrivnostneRazlike({ gameData }) {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.game.currentStep);
  const [errors, setErrors] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const { height, width } = useWindowDimensions();

  const success = () => {
    dispatch(completedGameReducer(gameData.id));
    dispatch(showSuccessReducer(true));
  };

  useEffect(() => {
    window.plausible("p_start_skrivnostne_razlike");
  }, []);
  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`messages ingame d-flex justify-content-center align-items-center ${gameData.id}`}>
      <HeadingStatus />
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          colors={["#bfa266", "#642076"]}
          numberOfPieces={200}
          friction={0.99}
          gravity={0.3}
          tweenDuration={500}
          recycle={false}
          opacity={0.8}
          drawShape={(ctx) => {
            function strokeStar(x, y, r, n, inset) {
              ctx.save();
              ctx.beginPath();
              ctx.translate(x, y);
              ctx.moveTo(0, 0 - r);
              for (var i = 0; i < n; i++) {
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r * inset);
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r);
              }
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            }
            strokeStar(5, 6, 3, 4, 4);
          }}
        />
      )}
      <div className="w-100 text-center skrivnostne-razlike">
        <Wrapper className="wrapper__narrow wide">
          <CountDown
            currentStep={currentStep}
            errors={errors}
            gameData={gameData}
          />

          <div className="game-container">
            <div className="image">
              <Razlike
                gameData={gameData}
                errors={errors}
                setErrors={setErrors}
                setShowConfetti={setShowConfetti}
              />
            </div>
          </div>

          <div className="dev-buttons">
            <Button
              label="Zaključi kot success"
              onClick={success}
              className="m-1 btn btn-sm btn-texture mt-4"
            />

            <Button
              label="Zaključi kot fail"
              onClick={() => dispatch(showFailReducer(true))}
              className="m-1 btn btn-sm btn-texture mt-4"
            />
          </div>
        </Wrapper>
      </div>
    </motion.div>
  );
}

export default SkrivnostneRazlike;
