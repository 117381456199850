import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";

import Button from "../../components/Button/Button";

import { fadeInOut, staggerItemsFade, itemFade } from "../../utils/animations";
import { imgLogo1, imgLogo2, imgLogo3, imgLogosMobile } from "../../assets";
import imgLogo4 from "../../assets/images/logo_wb.svg";

import { ReactComponent as ImgStarLeft } from "../../assets/images/stars_left.svg";
import { ReactComponent as ImgStarRight } from "../../assets/images/stars_right.svg";

function Step1({ play, stop }) {
  const clickSoundhandler = () => {
    stop();
    play();
    window.plausible("p_start");
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="hero">
      {/* START SIDEBAR DESKTOP */}
      <div className="sidebar text-center d-none d-md-block">
        <div className="logo-primary">
          <img className="mb-4" src={imgLogo1}></img>
          <img className="mb-5" src={imgLogo2}></img>
          <img className="mb-3" src={imgLogo3}></img>
        </div>

        <div className="logo-secondary">
          <img className="mb-3" src={imgLogo4} />
          <p>
            © & ™ WBEI. Publishing Rights © JKR. (s22)
            <br />© & ™ WBEI. Pravice © JKR. (s22)
          </p>
        </div>
      </div>
      {/* END SIDEBAR DESKTOP */}

      <div className="main d-flex align-items-center text-center">
        <motion.div
          className="position-relative w-100"
          variants={staggerItemsFade}
          initial="hidden"
          animate="show">
          <div className="headline-star-container">
            <motion.h1
              className="huge text-texture heading-star heading-star__big text-shadow"
              variants={itemFade}>
              <ImgStarLeft className="star star-left" />
              Čarobni
              <br />
              preizkusi
              <ImgStarRight className="star star-right" />
            </motion.h1>
          </div>

          <motion.div className="mt-2 mt-sm-5" variants={itemFade}>
            <p className="lead text-white shadow px-4">
              Za tiste, ki živijo za čarovnijo, je zbirka Wizarding World zdaj v
              Lidlu! Nadaljuj z zabavo s to igrivo dogodivščino.
            </p>
          </motion.div>

          <motion.p className="lead text-white px-4" variants={itemFade}>
            Podaj se na tri preizkušnje s čarobnimi figuricami in nepozabnimi
            predmeti iz namizne igre Čarobne figurice.
          </motion.p>

          <motion.div variants={itemFade}>
            <Link to="/o-igri" onClick={clickSoundhandler}>
              <Button label="Začni preizkus" className="btn btn-texture mt-0" />
            </Link>
          </motion.div>

          {/* START SIDEBAR MOBILE */}
          <div className="sidebar d-block d-md-none">
            <img className="w-100 mt-2" src={imgLogosMobile} />
            <div className="logo-secondary">
              <img className="mb-1" src={imgLogo4} />
              <p>
                © & ™ WBEI. Publishing Rights © JKR. (s22)
                <br />© & ™ WBEI. Pravice © JKR. (s22)
              </p>
            </div>
          </div>
          {/* END SIDEBAR MOBILE */}
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Step1;
