import { useState, useEffect } from "react";
import useSound from "use-sound";
import { DropTarget } from "react-drag-drop-container";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { dropIn } from "../../../../utils/animations";

import {
  itemsDraggedReducer,
  showSuccessReducer,
  completedGameReducer,
  currentStepReducer,
} from "../../../../store/gameSlice";

import soundStart from "../../../../assets/sounds/start.wav";
import soundTick from "../../../../assets/sounds/tick.wav";
import soundTickWin from "../../../../assets/sounds/tick-win.wav";
import soundWin from "../../../../assets/sounds/win.mp3";
import soundLost from "../../../../assets/sounds/lost.mp3";

function Drop({ targetKey, image, gameData, setShowConfetti }) {
  const dispatch = useDispatch();
  const itemsDragged = useSelector((state) => state.game.itemsDragged);
  const currentStep = useSelector((state) => state.game.currentStep);

  const numberOfDrags = gameData.dataGame[currentStep].dragImgs.length;

  const [dragMatch, setDragMatch] = useState(false);

  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [playTick] = useSound(soundTick, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickWin] = useSound(soundTickWin, {
    volume: 0.8,
    soundEnabled: isPlaying,
  });

  const [playWin] = useSound(soundWin, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const dropped = (e, dragData) => {
    e.containerElem.style.visibility = "hidden";

    setDragMatch(true);
    playTickWin();

    if (itemsDragged < numberOfDrags) {
      dispatch(itemsDraggedReducer(itemsDragged + 1));
    }
  };

  useEffect(() => {
    if (itemsDragged === numberOfDrags) {
      dispatch(itemsDraggedReducer(0));

      //setTimerClock(3000);
      if (currentStep === 1) {
        dispatch(completedGameReducer(gameData.id));
        dispatch(showSuccessReducer(true));
        playWin();
        window.plausible("p_success_carobni_magneti");
      }

      dispatch(currentStepReducer(1));
      setShowConfetti(true);
      //setDragMatch(false);
    }
  }, [itemsDragged]);

  useEffect(() => {
    dispatch(itemsDraggedReducer(0));
  }, []);

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`item ${dragMatch ? "found" : null}`}>
      <DropTarget targetKey={targetKey} onHit={dropped} dropData={"ree"}>
        <img src={image} />
      </DropTarget>
    </motion.div>
  );
}

export default Drop;
