// Import React stuff
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import useSound from "use-sound";

// Redux
import { isPlayingReducer } from "./store/gameSlice";

// Styles
import "./assets/styles/style.scss";

// Import views and components
import Step1 from "./views/Intro/Step1";
import Step2 from "./views/Intro/Step2";
import Game from "./views/Game/Game";
import Games from "./views/Game/Games";
import OptIn from "./views/OptIn/OptIn";
import Fail from "./views/Game/Fail/Fail";
import Success from "./views/Game/Success/Success";
import Nagrade from "./views/Nagrade/Nagrade";

// Import media
import soundBG from "./assets/sounds/bg.mp3";

const Pause = ({ pause }) => {
  return (
    <svg
      onClick={pause}
      className="bi bi-volume-up-fill soundicon"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
      <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
      <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
    </svg>
  );
};

const Play = ({ play }) => {
  return (
    <svg
      onClick={play}
      className="bi bi-volume-mute-fill soundicon"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
    </svg>
  );
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [play, { stop }] = useSound(soundBG, {
    volume: 0.3,
    soundEnabled: isPlaying,
    loop: true,
  });

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  useEffect(() => {
    if (isPlaying) {
      play();
      dispatch(isPlayingReducer(true));
    } else {
      stop();
      dispatch(isPlayingReducer(false));
    }
  }, [isPlaying]);

  return (
    <>
      <div id="turn">
        <h2 className="text-white">Obrni zaslon</h2>
      </div>

      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Step1 stop={stop} play={play} />} />
          <Route path="/o-igri" element={<Step2 />} />

          <Route path="game/:game" element={<Game />} />

          <Route path="/games" element={<Games />} />

          <Route path="/optin" element={<OptIn />} />

          <Route path="/fail" element={<Fail />} />
          <Route path="/success" element={<Success />} />
          <Route path="/nagrade" element={<Nagrade />} />
        </Routes>
      </AnimatePresence>
      <>
        {isPlaying ? (
          <Pause pause={() => dispatch(isPlayingReducer(false))} />
        ) : (
          <Play play={() => dispatch(isPlayingReducer(true))} />
        )}
      </>
    </>
  );
}

export default App;
