import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../utils/animations";
import Wrapper from "../../components/Wrapper/Wrapper";
import Button from "../../components/Button/Button";

import { ReactComponent as ImgStarLeft } from "../../assets/images/stars_left.svg";
import { ReactComponent as ImgStarRight } from "../../assets/images/stars_right.svg";

import imgIntro01 from "../../assets/images/icon_intro_01.svg";
import imgIntro02 from "../../assets/images/icon_intro_02.svg";
import imgIntro03 from "../../assets/images/icon_intro_03.svg";

function Step2() {
  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="messages d-flex justify-content-center align-items-center">
      <div className="w-100 text-center">
        <div className="headline-star-container">
          {" "}
          <h1 className="text-texture heading-star">
            <ImgStarLeft className="star star-left" />
            Čarobni
            <br />
            preizkusi <ImgStarRight className="star star-right" />
          </h1>
        </div>

        <Wrapper className="wrapper__full-width">
          <div className="content ">
            <motion.div
              className="row usps g-1 g-sm-5"
              variants={staggerItems}
              initial="hidden"
              animate="show">
              <motion.div className="col" variants={item}>
                <img src={imgIntro01} className="mb-3" />
                <p>Prehiti uro in uspešno odigraj vse tri igre.</p>
              </motion.div>
              <motion.div className="col" variants={item}>
                <img src={imgIntro02} className="mb-3" />
                <p>Z vsakim zaključenim izzivom se odpre nova igra.</p>
              </motion.div>
              <motion.div className="col" variants={item}>
                <img src={imgIntro03} className="mb-3" />
                <p>
                  Prijavi se v nagradni žreb in se poteguj za vznemirljive
                  nagrade.
                </p>
              </motion.div>
            </motion.div>
          </div>
        </Wrapper>

        <div className="button-container">
          <div className="row">
            <div className="col-12">
              <Link to="/games">
                <Button label="Igraj" className="btn btn-texture" />
              </Link>
            </div>
          </div>
          <div className="row mt-1 g-2">
            <div className="col-4">
              <Link to="/nagrade">
                <Button
                  label="Nagrade"
                  className="btn btn-sm btn-outline-white"
                />
              </Link>
            </div>
            <div className="col-8">
              <a
                className="btn btn-sm btn-outline-white"
                href="https://www.lidl.si/file-download/111234/1764011/Pravila%20in%20pogoji%20nagradne%20igre%20%C4%8Carobni%20preizkusi.pdf"
                target="_blank">
                Pravila in pogoji
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Step2;
