import { Link } from "react-router-dom";
import { ReactComponent as IconSound } from "../../assets/images/sound.svg";

function HeadingStatus() {
  return (
    <div className="heading-status">
      <Link to="/">
        <div className="heading">
          <h2 className="text-texture text-center mb-0">
            Čarobni
            <br />
            preizkusi
          </h2>
        </div>
      </Link>
    </div>
  );
}
 
export default HeadingStatus;
