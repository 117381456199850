import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showIntro: true,
  showSuccess: false,
  showFail: false,
  showOptIn: false,
  optIn: false,
  currentStep: 0,
  itemsDragged: 0,
  isPlaying: true,

  completedGame: [
    {
      id: "skrivnostnerazlike",
      completed: false,
    },
    {
      id: "zacaranspomin",
      completed: false,
    },
    {
      id: "carobnimagneti",
      completed: false,
    },
  ],
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    showIntroReducer: (state, action) => {
      state.showIntro = action.payload;
    },
    showSuccessReducer: (state, action) => {
      state.showSuccess = action.payload;
    },
    showFailReducer: (state, action) => {
      state.showFail = action.payload;
    },
    showOptInReducer: (state, action) => {
      state.showOptIn = action.payload;
    },
    optInReducer: (state, action) => {
      state.optIn = action.payload;
    },
    completedGameReducer: (state, action) => {
      const game = state.completedGame.find((e) => e.id === action.payload);

      if (game) {
        game.completed = true;
      }
    },

    currentStepReducer: (state, action) => {
      state.currentStep = action.payload;
    },

    itemsDraggedReducer: (state, action) => {
      state.itemsDragged = action.payload;
    },
    isPlayingReducer: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
});

export const {
  showIntroReducer,
  showSuccessReducer,
  showFailReducer,
  showOptInReducer,
  optInReducer,
  completedGameReducer,
  currentStepReducer,
  itemsDraggedReducer,
  isPlayingReducer,
} = gameSlice.actions;

export default gameSlice.reducer;
