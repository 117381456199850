export const imgLogo1 = require('./images/logo_harrypotter.png');
export const imgLogo2 = require('./images/logo_wizzardworld.png');
export const imgLogo3 = require('./images/logo_fantasticb.png');
export const imgLogosMobile = require('./images/logos_mobile.png');
export const imgBgGames = require('./images/bg_games.jpg');
export const imgKlubuk = require('./images/icon_klobuk.png');





