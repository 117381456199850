import useSound from "use-sound";
import { useDispatch, useSelector } from "react-redux";
import { isPlayingReducer } from "../../store/gameSlice";
import soundNavigate from "../../assets/sounds/navigate.wav";

function Button({ onClick, className, label }) {
  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [playNavigate] = useSound(soundNavigate, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const clickHandler = () => {
    playNavigate();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={className} onClick={clickHandler}>
      {label}
    </button>
  );
}

export default Button;
