import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../../../utils/animations";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";
import Confetti from "react-confetti";
import useWindowDimensions from "../../../../utils/useWindowDimensions";

import Drags from "./Drags";
import Drop from "./Drop";

import {
  showIntroReducer,
  showSuccessReducer,
  showFailReducer,
  completedGameReducer,
} from "../../../../store/gameSlice";

import CountDown from "../../../../components/CountDown/CountDown";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import HeadingStatus from "../../../../components/HeadingStatus/HeadingStatus";
import Button from "../../../../components/Button/Button";

// Assets

function CarobniMagneti({ gameData }) {
  const currentStep = useSelector((state) => state.game.currentStep);
  //const itemsDragged = useSelector((state) => state.game.itemsDragged);

  const [showPreview, setShowPreview] = useState(true);
  const [droppedImg, setDroppedImg] = useState(["", "", ""]);
  const [itemsFound, setItemsFound] = useState(0);

  const [errors, setErrors] = useState(0);
  const [showBoost, setShowBoost] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { height, width } = useWindowDimensions();

  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(completedGameReducer(gameData.id));
    dispatch(showSuccessReducer(true));
  };

  useEffect(() => {
    if (errors > 0) {
      setShowBoost(true);

      setTimeout(() => {
        setShowBoost(false);
      }, 500);
    }
  }, [errors]);

  useEffect(() => {
    window.plausible("p_start_carobni_magneti");
  }, []);

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`messages ingame d-flex justify-content-center align-items-center ${gameData.id}`}>
        <HeadingStatus />
        {showConfetti && (
          <Confetti
            width={width}
            height={height}
            colors={["#bfa266", "#642076"]}
            numberOfPieces={200}
            friction={0.99}
            gravity={0.3}
            tweenDuration={500}
            recycle={false}
            opacity={0.8}
            drawShape={(ctx) => {
              function strokeStar(x, y, r, n, inset) {
                ctx.save();
                ctx.beginPath();
                ctx.translate(x, y);
                ctx.moveTo(0, 0 - r);
                for (var i = 0; i < n; i++) {
                  ctx.rotate(Math.PI / n);
                  ctx.lineTo(0, 0 - r * inset);
                  ctx.rotate(Math.PI / n);
                  ctx.lineTo(0, 0 - r);
                }
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              }
              strokeStar(5, 6, 3, 4, 4);
            }}
          />
        )}
        <div className="w-100 text-center">
          <Wrapper className="wrapper__narrow carobni-magneti">
            <CountDown
              currentStep={currentStep}
              errors={errors}
              gameData={gameData}
            />
            {showBoost ? (
              <div className="score-booster">
                <span>-1s</span>
              </div>
            ) : null}
            <div className="drags mt-sm-2">
              {gameData.dataGame[currentStep].dragImgs.map((value, i) => (
                <>
                  <Drags
                    key={i + "_" + currentStep}
                    image={value.img}
                    targetKey={value.ident}
                    setErrors={setErrors}
                    errors={errors}
                  />
                </>
              ))}
            </div>

            <div className="drops">
              {/*  <DropElements /> */}
              {gameData.dataGame[currentStep].dropImgs.map((value, i) => (
                <Drop
                  key={i + "_" + currentStep}
                  image={value.img}
                  targetKey={value.ident}
                  gameData={gameData}
                  setShowConfetti={setShowConfetti}
                />
              ))}
            </div>

            <div className="dev-buttons">
              <Button
                label="Zaključi kot success"
                onClick={clickHandler}
                className="m-1 btn btn-sm btn-texture mt-4"
              />

              <Button
                label="Zaključi kot fail"
                onClick={() => dispatch(showFailReducer(true))}
                className="m-1 btn btn-sm btn-texture mt-4"
              />
            </div>
          </Wrapper>
        </div>
      </motion.div>
    </>
  );
}

export default CarobniMagneti;
